/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    blockquote: "blockquote",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Me veo en la obligación de copiar un comentario al respecto del a", React.createElement(_components.a, {
    href: "http://www.google.es/imgres?imgurl=http://alt1040.com/files/2010/11/red-neutral.jpg&imgrefurl=http://alt1040.com/2010/11/manifiesto-por-una-red-neutral&usg=__mITa3K4Dh6rqmQnt0QW21nze_UY=&h=378&w=320&sz=52&hl=es&start=0&zoom=1&tbnid=dEU6PPkpBEqyGM:&tbnh=137&tbnw=116&ei=UeNCTdP4GNC48gPx9uX6Dw&prev=/images%3Fq%3Dred%2Bneutral%26um%3D1%26hl%3Des%26client%3Dfirefox-a%26rls%3Dorg.mozilla:es-ES:official%26biw%3D1280%26bih%3D906%26tbs%3Disch:1&um=1&itbs=1&iact=hc&vpx=770&vpy=54&dur=6419&hovh=244&hovw=207&tx=102&ty=136&oei=UeNCTdP4GNC48gPx9uX6Dw&esq=1&page=1&ndsp=34&ved=1t:429,r:3,s:0"
  }, React.createElement(_components.img, {
    src: "http://t2.gstatic.com/images?q=tbn:ANd9GcQDJZ8V6tbrEP-8hHznrOkFde8Plezfy-0i2oGunkyk5FP58e8tfA",
    alt: ""
  })), "pagón de Internet egipcio. Este apagón es todo un ejemplo de porque no son de fiar las conexiones a Internet mediante las clásicas empresas ISP, las empresas no son de fiar como se ha venido demostrando durante el año pasado con los boicots a Wikileaks y ahora esta desconexión de todo un país. No es algo que nos debería sorprender, el objetivo de toda empresa es obtener beneficios, la ética empresarial es algo que está por debajo de este objetivo principal.  Entonces ¿que podemos hacer para no depender de las empresas y por lo tanto de los gobiernos?"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "http://blog.viricmind.org/"
  }, "Andreu"))), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://alt1040.com/2011/01/grafica-de-una-desconexion-anunciada-egipto#comment-454696"
  }, "28 Enero, 2011")), "\n", React.createElement(_components.p, null, "Existen posibilidades técnicas interesantes, como crear redes cableadas gestionadas por los ciudadanos (aunque implicaría la necesidad de una cierta formación), o,"), "\n", React.createElement(_components.p, null, "algo más fácil, seguir la estela de proyectos como guifi.net y crear “aplicaciones web de intranet” al menos para mantener la comunicación interna si todos los puntos de acceso de esa red ciudadana hacia la WWW quedaran cerrados."), "\n", React.createElement(_components.p, null, "Hay que decir que igualmente esas alternativas pecan de una vulnerabilidad importante, la dependencia del sistema eléctrico, que también puede ser controlado en última instancia por el gobierno. Es por eso que también es interesante incentivar las formas de autogeneración, que son buenas por tres motivos básicos, reducen nuestra dependencia energética, son más ecológicas y nos pueden permitir aguantar incluso en tiempos de bloqueos importantes."), "\n"), "\n", React.createElement(_components.p, null, "Ahí está la clave, la creación de una LibreNet, una red de comunicaciones creada por los propios ciudadanos, tal y como lo hace la comunidad guifi.net, y para sostener estas redes crear una red eléctrica con una generación distribuida, inteligente y controlada por los usuarios, lo que se conoce con el nombre Smart Grid pero con el añadido de no depender de las compañías eléctricas."), "\n", React.createElement(_components.p, null, "Solo de esta forma conseguiremos una red libre y neutral."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
